<template>
  <div class="privacy-policy-box">
      <title-nav :title="'隐私政策'"/>
      <div class="text" v-for="item in privacyPolicy" :key="item.id">
        <div class="title">{{ item.title }}</div>
        <div class="content">{{  item.content  }}</div>
      </div>
  </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'

export default {
name: 'PrivacyPolicy',
components: {
  TitleNav,
},
mounted(){
},
data:()=>{
  return {
    privacyPolicy:[{
      id: '1',
      title:'一、信息收集',
      content:'个人信息收集范围： 福五金将收集与服务提供相关的个人信息，包括但不限于身份验证、服务支持、安全功能等所需信息。收集方式： 通过您的使用、注册等方式，福五金收集个人信息。'
    },{
      id: '2',
      title:'二、信息使用目的',
      content:'服务提供： 为用户提供产品和服务。 安全与反欺诈： 用于识别用户、验证身份，仅在符合法定条件下使用于反欺诈目的。 客户支持： 提供客户支持服务。'
    },{
      id: '3',
      title:'三、Cookie与类似技术',
      content:'通过Cookie等技术收集信息，用于身份验证、提升服务质量和用户体验。'
    },{
      id: '4',
      title:'四、信息共享、转让与公开披露',
      content:'委托处理： 与第三方签署保密协定，对其进行监督。 共享： 不出售个人信息，仅在法律允许范围内分享，包括关联公司、法定要求等。 转让： 在明确同意或法定情况下转让个人信息。公开披露： 在明确同意或法律要求下进行。'
    },{
      id: '5',
      title:'五、个人信息安全保护',
      content:'采取物理、电子、管理等措施保障信息安全，包括SSL、加密存储等。'
    },{
      id: '6',
      title:'六、信息存储与存储期限',
      content:'信息存储原则在中国境内，除法律规定或用户同意外不转移。存储期限最小化，符合法律规定。'
    },{
      id: '7',
      title:'七、用户权利',
      content:'用户享有访问、更正、删除、撤回同意等权利，我们将在15天内回应。'
    },{
      id: '8',
      title:'八、隐私政策变更',
      content:'可能不时修改，对于重大变更将提供显著通知。'
    },{
      id: '9',
      title:'九、其他第三方及其服务',
      content:'第三方链接不受本公司控制，用户应注意其隐私政策。'
    },{
      id: '10',
      title:'十、未成年人保护',
      content:'服务面向18周岁以上成年人，对未满14周岁儿童提供服务需获得监护人同意。'
    },{
      id: '11',
      title:'十一、本隐私政策变更',
      content:'会根据业务和法规调整，重大变更将提供显著通知。'
    },{
      id: '12',
      title:'十二、联系我们',
      content:'如有疑问，请通过提供的联系方式与我们联系。'
    }]
  }
},
methods:{
},
}
</script>

<style scoped lang="scss">
.privacy-policy-box {
  min-height: 100vh;
  background: #fafafa;
  padding: 60px 15px 0;
  color: #222;
  .text {
    text-align: left;
    padding-bottom: 15px;
    word-break: break-all;
    .title {
      font-size: 18px;
      padding: 0px 0;
    }
    .content {
      padding: 10px 0;
    }
  }
}
</style>